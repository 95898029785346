import React from 'react'
import styles from './alert_banner.module.css'

const AlertBanner = () => {
  return (
    <div className={styles.root}>
      <p>
        <b>
          <span role='img' aria-label='Siren'>
            🚨
          </span>{' '}
          We have been notified of a fraudulent website claiming to offer employment opportunities at Envato.
        </b>
      </p>
      <p>The security and safety of our Authors, Customers, and all users is of paramount importance to us.</p>
      <p>
        To ensure the legitimacy of job postings, apply only through our official careers website:{' '}
        <a href='https://www.envato.com/about/careers/'>https://www.envato.com/about/careers/</a>.
      </p>
      <p>
        If you have any concerns about job advertisements on LinkedIn or other platforms, please contact us at,{' '}
        <a href='mailto:business@envato.com'>business@envato.com</a> for verification.
      </p>
    </div>
  )
}

export default AlertBanner
