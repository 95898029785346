import React from 'react'
import { Analytics } from '@envato/google-analytics'
import CareersHeader from '../../../components/careers_header/careers_header'
import CareersIntro from '../../../components/careers_intro/careers_intro'
import Masonry from '../../../components/masonry'
import OurOffices from '../../../components/our_offices'
import ValuesSection from '../../../components/values_section'
import OpenPositions, { locations } from '../../../components/open_positions'
import { useTranslation } from '../../../i18n'
import translations from './index.translations'
import WhyWorkHereSection from '../../../components/why_work_here_section'
import Accolades from '../../../components/accolades'

import basketball from '../../../images/careers_masonry/basketball.jpg'
import courtyard from '../../../images/careers_masonry/courtyard.jpg'
import employees from '../../../images/careers_masonry/employees.jpg'
import fire from '../../../images/careers_masonry/fire.jpg'
import meeting from '../../../images/careers_masonry/meeting.jpg'
import mexico from '../../../images/careers_masonry/mexico.jpg'
import office from '../../../images/careers_masonry/office.jpg'
import painting from '../../../images/careers_masonry/painting.jpg'
import yoga from '../../../images/careers_masonry/yoga.jpg'
import AlertBanner from '../../../components/alert_banner/alert_banner'

export default () => {
  const t = useTranslation(translations)
  const images = [
    { src: office, alt: t.masonry.office },
    { src: courtyard, alt: t.masonry.courtyard },
    { src: employees, alt: t.masonry.employees },
    { src: mexico, alt: t.masonry.mexico },
    { src: meeting, alt: t.masonry.meeting },
    { src: basketball, alt: t.masonry.basketball },
    { src: fire, alt: t.masonry.fire },
    { src: painting, alt: t.masonry.painting },
    { src: yoga, alt: t.masonry.yoga }
  ]

  return (
    <>
      <AlertBanner />
      <Analytics.Section trackingId='header'>
        <CareersHeader />
      </Analytics.Section>
      <Analytics.Section trackingId='intro'>
        <CareersIntro />
      </Analytics.Section>
      <Masonry images={images} />
      <Analytics.Section trackingId='our_offices'>
        <OurOffices />
      </Analytics.Section>
      <Analytics.Section trackingId='why_work_here'>
        <WhyWorkHereSection />
      </Analytics.Section>
      <Analytics.Section trackingId='open_positions'>
        <div id='positions'>
          <OpenPositions location={locations.all} />
        </div>
      </Analytics.Section>
      <ValuesSection background='white' />
      <Analytics.Section trackingId='accolades'>
        <Accolades />
      </Analytics.Section>
    </>
  )
}
